import React, { Component } from 'react';
import { Loader } from "@googlemaps/js-api-loader"
import MarkerClusterer from '@googlemaps/markerclustererplus';

import { mapDarkModeStyle } from './dark-mode.js';

import arrow from './my-arrow.png';
import './map-layer.scss';

const mapsAPIKey = 'AIzaSyAvmy4kfGnLGOSYkrXlwnPtLE05zT1WSjs';

const mapDefaultStyles = [
  {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
  }
];

const mapsInitialOptions = {
  center: { lat: 50.083956, lng: 14.415276 },
  zoom: 11,
  mapTypeId: 'roadmap',
  styles: mapDefaultStyles,
  disableDefaultUI: true,
}

export class MapLayer extends Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.markerCluster = null;
    this.activeMarker = null;
    this.defaultIcon = this.props.pinDefaultIcon;
    this.activeIcon = this.props.pinActiveIcon;

    this.state = {
      darkMode: false,
    }
  }

  getCurrentPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.map.setCenter(pos);
          
          new window.google.maps.Marker({
            position: pos,
            map: this.map,
            title: 'My Car Position',
            icon: `${arrow}`,
            clickable: false,
          });
        },
        () => {
          console.log('Location properly set',this.mapLayer.getCenter());
        }
      );
    } else {
      // Browser doesn't support Geolocation
      console.log('Browser or Device doesn\'t support Geolocation');
    }
  }

  setMapStyle = (mapStyle) => {
    this.map.setOptions({
      styles: mapStyle,
    });
  }

  placeMarkers = (markerData) => {
    const markers = markerData.map((location, i) => {
      const mapMarker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(location.latitude, location.longitude),
        title: location.name,
        icon: `${this.defaultIcon}`,
        customMarkerData: location,
      });
      mapMarker.addListener('click', ()=> {
        if (this.activeMarker) {
          this.activeMarker.setIcon(`${this.defaultIcon}`);
        }
        this.activeMarker = mapMarker;
        this.map.setCenter(this.activeMarker.getPosition());
        this.map.panBy(+200,+50);
        this.props.markerClickEvent(this.activeMarker);
        this.activeMarker.setIcon(this.activeIcon);
      });
      return mapMarker;
    });
    this.markerCluster = new MarkerClusterer(this.map, markers,{ imagePath: '/assets/images/mc-cluster'});
  }

  componentDidUpdate(prevProps) {
    if (this.props.darkModeState !== prevProps.darkModeState) {
        this.setState({darkMode: this.props.darkModeState});
        if (this.props.darkModeState) {
          this.setMapStyle(mapDarkModeStyle);
        } else {
          this.setMapStyle(mapDefaultStyles);
        }
    }
    if (this.props.isDetailOpen !== prevProps.isDetailOpen) {
      if (!this.props.isDetailOpen && this.activeMarker){
        this.activeMarker.setIcon(`${this.defaultIcon}`);
        this.activeMarker = null;
      }
    }
  }

  componentDidMount = () => {
    const loader = new Loader({
      apiKey: mapsAPIKey,
      version: "weekly",
      ...mapsInitialOptions,
    });
    
    loader.load().then(() => {
      this.map = new window.google.maps.Map(document.getElementById("map"), mapsInitialOptions);
      this.getCurrentPosition(this.map);
      this.placeMarkers(this.props.markerData);
      this.props.mapLoadEvent(this);
    });
  }

  render() {
    return (
      <div id="map" className="map-layer"></div>
    )
  }
}

