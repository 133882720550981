import React, {Component} from 'react';
import Countdown from 'react-countdown';
import axios from 'axios';
import './order-container.scss';

const fixProductName = (stringValue) => {
  const lowerCased = stringValue.toLowerCase();
  const fixed = lowerCased
  .replace('mcd','McD')
  .replace('mcm','McM')
  .replace('mcc','McC')
  .replace('mcf','McF')
  .replace('mcr','McR')
  .replace('mcn','McN')
  .replace(' mac',' Mac')
  .replace('king','King')
  .replace('big ','Big ')
  .replace('tasty ','Tasty ');
  return fixed.charAt(0).toUpperCase() + fixed.slice(1) 
}

const fixSeconds = (seconds) => {
  if (seconds > 9) 
    return seconds;
  else
    return `0${seconds}`;
}

export class OrderContainer extends Component {
  constructor(props) {
    super(props);
    this.apiBasePath = 'https://web.sact.dd-dev.digital';
    this.productKey = 'mcdonalds';
    this.testingUserAccount = 'mjech@deloittece.com';
    this.orderTime = 1000*60*15 // 15 minutes
    this.state = { 
      productDetail: null,
      orderSubmit: false,
    };
  }
  
  openProductDetail = (evt,data) => {
    evt.preventDefault();
    this.setState({ productDetail:data, orderSubmit: false })
  }

  clickBackToList = (evt) => {
    evt.preventDefault();
    this.setState({ productDetail: null })
  }

  submitOrder = (evt) => {
    evt.preventDefault();
    
    axios.post(`${this.apiBasePath}/service/buyProduct`,{
      'customerEmail': this.testingUserAccount,
      'price': this.state.productDetail.price,
      'productKey': this.productKey
    })
    .then((response) => {
      console.log('Order submit success',response);
    })
    .catch((error) => {
      console.log('Order submit error',error);
    });
    this.setState({ orderSubmit: true })
  }

  render() {
    return (
      <div className="order-container">
        <button className="order-container--close" onClick={this.props.handleClose}>x</button>
        {(!this.state.productDetail) ?
        <>
          <div className="order-container--header">
            <h1 className="order-container--headline">{this.props.branchData.name}</h1>
            <h2 className="order-container--subheadline">{this.props.branchData.address}</h2>
          </div>
          <div className="order-container--list">
            {
              this.props.menuData.map((menuGroup,i) => {
                return (menuGroup.products.length > 0) ? 
                <div className="order-container--menu-group" key={`menu-${menuGroup.id}`}>
                  <h3>{menuGroup.name}</h3>
                  {
                    menuGroup.products.map((productItem,i) => {
                      return <a href="/" className="order-container--menu-item" onClick={(evt) => this.openProductDetail(evt,productItem)}>
                          <div className="order-container--menu-item--image">
                            <img src={productItem.logo_path} alt={productItem.name} />
                          </div>
                          <h4 className="order-container--menu-item--title">
                            {fixProductName(productItem.name)}
                          </h4>
                          <a href="/" className="order-container--menu-item--button">{(productItem.price)? productItem.price: '0'} Kč</a>
                        </a>
                    })
                  }
                </div>
                : null;
              })
            }
          </div>
        </>
        :
          (!this.state.orderSubmit) ?
            <>
              <div className="order-container--header">
                <a href="/" className="order-container--back-button" onClick={this.clickBackToList}><span>←</span> Zpět na menu</a>
              </div>
              <div className="order-container--detail">
                <div className="order-container--detail--img-container">
                  <img src={this.state.productDetail.file_path} alt={this.state.productDetail.name} />
                </div>
                <div className="order-container--detail--inner">
                  <h2>{fixProductName(this.state.productDetail.name)}</h2>
                  <span className="order-container--detail--price">{(this.state.productDetail.price)? this.state.productDetail.price: '0'} Kč</span>
                  <a href="/" className="order-container--detail--button" onClick={this.submitOrder}>Objednat</a>
                </div>
              </div>
            </>
          : <>
            <div className="order--confirmation">
              <div className="order-confirmation--image">
                <img src={this.state.productDetail.file_path} alt={this.state.productDetail.name} />
                <span class="order-confirmation-icon"></span>
              </div>
              <div className="order-confirmation--info">
                <h2>{fixProductName(this.state.productDetail.name)}<br />se připravuje!</h2>
                <button className="order-confirmation--button" onClick={this.props.handleClose}>
                  K vyzvednutí za 
                  <Countdown zeroPadTime={2} date={Date.now() + this.orderTime} renderer={props => <span> {props.minutes}:{fixSeconds(props.seconds)} </span>} />
                  minut
                </button>
              </div>
            </div>
          </>
        }
      </div>
    )
  }
};
