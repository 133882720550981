import React from 'react';
import './dashboard.scss';

export const Dashboard = ({children}) => (
  <div className="dashboard">
    <div className="dashboard--container">
      {children}
    </div>
  </div>
)

