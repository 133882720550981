import React, { Component} from 'react';
import { MapLayer, OrderContainer } from '../';
import * as mcDonaldMapData from "./mcdonald-branches.json";
import * as menuOrderData from "./menu/menu-data.json";

import defaultPinIcon from './markers/pin.svg';
import activePinIcon from './markers/pin_active.svg';

import './mc-donald.scss';

export class McDonald extends Component {
  constructor(props) {
    super(props);
    this.state = { openDataLayer: null };
  }

  onMapLoaded = (evt) => {
    console.log(evt);
  };

  onMarkerClick = (evt) => {
    const markerData = evt.customMarkerData;
    this.setState({ openDataLayer: markerData });
  }

  onContainerClose = (evt) => {
    this.setState({ openDataLayer: null });
  }

  render() {
  return (
    <div className="mc-donald">
      <MapLayer darkModeState={this.props.darkModeState} mapLoadEvent={this.onMapLoaded} markerClickEvent={this.onMarkerClick} markerData={mcDonaldMapData.restaurants} pinDefaultIcon={defaultPinIcon} pinActiveIcon={activePinIcon} isDetailOpen={this.state.openDataLayer} />
      {this.state.openDataLayer ? 
        <OrderContainer branchData={this.state.openDataLayer} menuData={menuOrderData.data} handleClose={this.onContainerClose} />
      : ''}
    </div>
  )};
};
