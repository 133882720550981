import React, { Component } from 'react';
import './App.scss';
import { DarkModeSwitch, Dashboard, McDonald } from './components';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {darkMode: false};
  }

  handleModeState = (evt) => {
    const isDarkMode = evt.target.checked;
    this.setState({ darkMode: isDarkMode });
  }

  render() { 
    return (
      <>
        <Dashboard>
          <McDonald darkModeState={this.state.darkMode} />
          <DarkModeSwitch onToggleSwitch={this.handleModeState} />
        </Dashboard>
      </>
  )};
};


export default App;
