import React from 'react';
import './dark-mode-switch.scss';

export const DarkModeSwitch = ({ onToggleSwitch = () => {} }) => (
  <div className="dark-mode">
    <label id="switch" className="dark-mode--switch">
      <input type="checkbox" id="slider" onChange={(evt) => onToggleSwitch(evt)} />
      <span className="dark-mode--slider dark-mode--slider-round"></span>
    </label>
  </div>
)

